import React from 'react'
import { css } from 'linaria'

import { useTranslation } from '@/hooks/I18nProvider'

const styles = css`
  @apply flex flex-col pt-8 pb-4 px-4 items-center;

  svg {
    @apply mb-2;
  }

  > span {
    @apply text-center text-gray-400;
    font-size: 11px;

    a {
      @apply cursor-pointer no-underline font-bold text-gray-400;
    }
  }
`

const Footer = () => {
  const { t } = useTranslation()
  return (
    <>
      <footer className={styles}>
        <span>
          {`${t`footer.developedBy`} ${t`footer.companyName`} `}
          <br />
          <br />
          <span>
            {`${t`footer.disclaimerText`} `}
            <a
              href="https://n1co.com/wp-content/uploads/2022/11/Terminos-y-Condiciones-USUARIOS-n1co-.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {t`footer.termsText`}
            </a>
          </span>
        </span>
      </footer>
    </>
  )
}

export default Footer
