import React, { useState } from 'react'
import classNames from 'classnames'
import { css } from 'linaria'
import { useTranslation } from '@/hooks/I18nProvider'

import { ReactComponent as HelpIcon } from './assets/help-circle.svg'

const styles = css`
  @apply sticky block items-center bg-red-100
    border-b-2 border-red-300 shadow-md text-red-600 top-0 z-10;
  & > h2.title {
    @apply flex justify-center py-2;
    & > button {
      @apply flex items-center gap-1 justify-center bg-transparent text-red-600 text-base;
      & > span {
        @apply text-red-600;
      }
      & > svg {
        @apply w-4 h-4 cursor-pointer;
      }
    }
  }

  & > div.helper-text-container {
    @apply grid overflow-hidden transition-all duration-300 ease-in-out opacity-0;
    grid-template-rows: 0fr;
    &.open {
      @apply opacity-100;
      grid-template-rows: 1fr;
    }
    & > div {
      @apply text-xs text-center overflow-hidden;
      & > p {
        @apply mx-4 mb-3;
      }
    }
  }
`

const SandboxHead = () => {
  const [accordionOpen, setAccordionOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <div className={styles}>
      <h2 className="title">
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault()
            setAccordionOpen(!accordionOpen)
          }}
        >
          <span>
            <b>Sandbox</b>
            Mode
          </span>
          <HelpIcon />
        </button>
      </h2>
      <div className={classNames('helper-text-container', { open: accordionOpen })}>
        <div>
          <p>
            {t`sandbox.bannerMessage`}
          </p>
        </div>
      </div>
    </div>
  )
}

export default SandboxHead
