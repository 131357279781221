const getCycleText = (cycle, number, t, isUnit) => {
  if (number && cycle) {
    switch (cycle) {
      case 'Day':
        return isUnit ? `${t`link.day`}${number > 1 ? 's' : ''}` : t`link.daily`
      case 'Week':
        return isUnit ? `${t`link.week`}${number > 1 ? 's' : ''}` : t`link.weekly`
      case 'Month':
        return isUnit ? `${number > 1 ? t`link.months` : t`link.month`}` : t`link.monthly`
      case 'Year':
        return isUnit ? `${t`link.year`}${number > 1 ? 's' : ''}` : t`link.yearly`
      default:
        return ''
    }
  }
  return ''
}

const getCycleTextComplete = (cycleStr, timePeriod, t, haveEachText = false, useUnit = true) => {
  let cycleText = ''
  const isUnit = timePeriod === 1
  const eachText = haveEachText ? `${t`link.eachText`} ` : ''

  if (cycleStr && timePeriod) {
    switch (cycleStr.toUpperCase()) {
      case 'DAY':
        cycleText = (!isUnit || !useUnit)
          ? `${eachText}${timePeriod} ${t`link.day`}${timePeriod > 1 ? 's' : ''}`
          : t`link.daily`
        break
      case 'WEEK':
        cycleText = (!isUnit || !useUnit) ? `${eachText}${timePeriod} ${t`link.week`}${timePeriod > 1 ? 's' : ''}` : t`link.weekly`
        break
      case 'MONTH':
        cycleText = (!isUnit || !useUnit) ? `${eachText}${timePeriod} ${timePeriod > 1 ? t`link.months` : t`link.month`}` : t`link.monthly`
        break
      case 'YEAR':
        cycleText = (!isUnit || !useUnit) ? `${eachText}${timePeriod} ${t`link.year`}${timePeriod > 1 ? 's' : ''}` : t`link.yearly`
        break
      default:
        cycleText = ''
    }
  }
  return cycleText
}

export { getCycleText, getCycleTextComplete }
