const billPaymentsStore = (store) => {
  store.on('@init', () => ({
    billPayments: {
      country: {},
    },
    checkout: {},
  }))

  store.on('billlPayments/setCountry', (_, data) => ({ billPayments: { country: data } }))
  store.on('billPayments/setCheckout', ({ billPayments }, data) => ({ billPayments: { ...billPayments, checkout: data } }))
}

export default billPaymentsStore
