const customFieldValuesStore = (store) => {
  store.on('@init', () => ({
    customFields: null,
  }))

  store.on('customFields/setData', ({ customFields }, { name, value }) => ({
    customFields: customFields.map((item) => (
      item.name === name ? { ...item, value } : item
    )),
  }))

  store.on('customFields/set', (_, newFields) => ({ customFields: newFields }))
}

export default customFieldValuesStore
