// this is an experiment. This doesn't actually use a store
// but only the event system
import Smartlook from 'smartlook-client'
import { routerChanged } from '@storeon/router'

const trackStore = (store) => {
  // note there is no init event

  // note the first param must always be dont care
  store.on('track/identify', (_, user) => {
    Smartlook.identify(user.phone, user)
    return null
  })

  store.on('track/transaction', (_, transaction) => {
    Smartlook.track('transaction', transaction)
    return null
  })

  store.on(routerChanged, (_, { path, match }) => {
    Smartlook.navigation(path)
    Smartlook.track('page', match)
    Smartlook.track(`page-${match.page}`)
    return null
  })

  store.on('track/login', (_, user) => {
    Smartlook.identify(user.phone, user)
    Smartlook.track('login', user)
    return null
  })
}

export default trackStore
