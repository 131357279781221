import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'linaria'

import { useTranslation } from '@/hooks/I18nProvider'

const styles = css`
  @apply max-w-2xl w-full mx-auto p-6 flex flex-col items-center
  relative justify-center bg-gray-100 sticky top-0;
  min-width: 300px;
  z-index: 1;

  & > .logo {
    @apply rounded-full flex justify-center items-center w-12 h-12 m-auto border-solid border border-gray-300;
    background: #ececec;

    @screen lg {
      @apply w-32 h-32;
    }

    &.with-link {
      @apply cursor-pointer;
    }

    & > img {
      @apply rounded-full object-cover object-center;
      aspect-ratio: 1/1;
    }
  }

  header {
    @apply pb-4 px-4 relative;
    h1 {
      @apply text-gray-600 font-bold text-lg mb-3;

      span {
        @apply text-2xl block;
      }
    }
  }


  > h2 {
    @apply text-xs bg-gray-100 rounded-full py-2 px-4 text-gray-500 m-auto;
    width: fit-content;
    & > span {
      @apply font-bold;
    }
  }

  & > div.estimated {
    @apply mt-4;
    & p {
      @apply font-bold text-sm text-gray-500 mb-2;
    }
  }
`

const Header = ({
  estimated,
  storeImageUrl,
  storeName,
  orderType,
  orderId,
  withoutBottom,
  storeUrl,
}) => {
  const { t } = useTranslation()

  return (
    <div className={`${styles} ${withoutBottom ? 'without-bottom' : ''}`}>
      <div
        className={`logo ${storeUrl ? 'with-link' : ''}`}
        onClick={() => {
          if (storeUrl) {
            window.location.replace(storeUrl || '')
          }
        }}
      >
        <img src={storeImageUrl || '/images/n1co-icon.svg'} />
      </div>
      <header className={Object.keys(estimated).length ? 'has-estimated' : ''}>
        {storeName && <h1>{storeName}</h1>}
        {estimated.description && (
        <div className="estimated">
          {estimated.date && (
          <p>
            {t`header.datetimeDeliveryTitle`}
          </p>
          )}
          <h1>
            {estimated.date && (
            <>
              {estimated.date}
              <span>{estimated.time}</span>
            </>
            )}
            <span>{estimated.description}</span>
          </h1>
        </div>
        )}
      </header>
      {orderId && (
      <h2>
        <span>{orderType}</span>
        {' '}
        {orderId}
      </h2>
      )}
    </div>
  )
}

Header.propTypes = {
  estimated: PropTypes.shape({
    date: PropTypes.string,
    time: PropTypes.string,
    description: PropTypes.string,
  }),
  storeImageUrl: PropTypes.string,
  storeName: PropTypes.string,
  orderType: PropTypes.string,
  orderId: PropTypes.string,
  withoutBottom: PropTypes.bool,
  storeUrl: PropTypes.string,
}

Header.defaultProps = {
  estimated: {},
  storeImageUrl: '',
  storeName: '',
  orderType: 'Orden',
  orderId: null,
  withoutBottom: false,
  storeUrl: null,
}

export default Header

export { default as Sidebar } from './Sidebar'
