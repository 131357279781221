import React, { Suspense } from 'react'
import {
  elementType, object, func,
} from 'prop-types'

const SusEmpty = ({
  Component, props, translate,
}) => (
  <Suspense fallback={<div />}>
    {Component ? <Component {...props} t={translate} /> : null}
  </Suspense>
)

SusEmpty.propTypes = {
  Component: elementType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  props: object,
  translate: func,
}

SusEmpty.defaultProps = {
  props: {},
  translate: () => {},
}

export default SusEmpty
