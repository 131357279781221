const invoiceStore = (store) => {
  store.on('@init', () => ({
    invoice: {
      name: 'C/F',
      nit: null,
      address: null,
    },
  }))

  store.on('invoice/save', ({ invoice }, data) => ({ invoice: { ...invoice, ...data } }))
}

export default invoiceStore
