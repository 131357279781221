const userStore = (store) => {
  store.on('@init', () => ({
    user: {
      userData: {},
      isAuthenticated: false, // authenticated refers to the user having gone through the login process
      // isValidated: false, // validated refers to expired tokens, must not be stored in local storage
      idToken: '',
      accessToken: '',
      refreshToken: '',
      country: null,
      tokenOffset: 0,
    },
  }))

  store.on('user/setData', ({ user }, userData) => ({ user: { ...user, userData: { ...user.userData, ...userData } } }))

  store.on('user/set', ({ user }, newUser) => ({ user: { ...user, ...newUser } }))

  store.on('setLinkData', ({ user }, data) => ({
    user: {
      ...user, userData: { ...user.userData, ...data.user },
    },
    invoice: { ...data.invoice },
    link: { ...data.link },
    metadata: { ...data.metadata },
  }))

  store.on('user/setIsAuthenticated', ({ user }, isAuthenticated) => ({ user: { ...user, isAuthenticated } }))

  store.on('user/setCountry', ({ user }, country) => ({ user: { ...user, country } }))
}

export default userStore
