import React from 'react'
import PropTypes from 'prop-types'
import { AuthContext } from '@hb/web-hooks'

const {
  H4B_AUTH_API_SECURE: AUTH_SECURE,
  H4B_AUTH_API_URL: AUTH_URL,
  H4B_AUTH_API_PORT: AUTH_PORT,
  H4B_AUTH_API_MOUNT: AUTH_MOUNT,
} = h4benv

const AuthProvider = ({ children }) => {
  const config = {
    apiSecure: AUTH_SECURE || false,
    apiUrl: AUTH_URL || '127.0.0.1',
    apiPort: AUTH_PORT || '3008',
    apiMount: AUTH_MOUNT || 'auth',
  }

  return (
    <AuthContext.Provider value={{ config }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthProvider
