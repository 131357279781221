import { lazy } from 'react'

const componentsMap = new Map()

const getPageComponent = (moduleToLoad) => {
  if (componentsMap.has(moduleToLoad)) {
    return componentsMap.get(moduleToLoad)
  }

  let PageComponent = null

  switch (moduleToLoad) {
    case 'authorization':
      PageComponent = lazy(() => import('./Auth'))
      break
    case 'logout':
      PageComponent = lazy(() => import('./Logout'))
      break
    case 'invalidLink':
      PageComponent = lazy(() => import('./Invalid'))
      break
    case 'serverError':
      PageComponent = lazy(() => import('./ServerError'))
      break
    case 'link':
      PageComponent = lazy(() => import('./Link'))
      break
    case 'loading':
      PageComponent = lazy(() => import('./Loading'))
      break
    case 'wuhu':
      PageComponent = lazy(() => import('./Wuhu'))
      break
    case 'canceled':
      PageComponent = lazy(() => import('./Canceled'))
      break
    case 'status':
      PageComponent = lazy(() => import('./Status'))
      break
    case 'permalink':
      PageComponent = lazy(() => import('./Permalink'))
      break
    case 'profile':
      PageComponent = lazy(() => import('./Profile'))
      break
    case 'billpay-selection':
      PageComponent = lazy(() => import('./BillPayments/ServiceSelection'))
      break
    case 'billpay-service':
      PageComponent = lazy(() => import('./BillPayments/ServiceInformation'))
      break
    case 'billpay-detail':
      PageComponent = lazy(() => import('./BillPayments/BillsDetail'))
      break
    case 'billpay-login':
      PageComponent = lazy(() => import('./BillPayments/LoginPage'))
      break
    case 'billpay-checkout':
      PageComponent = lazy(() => import('./BillPayments/Checkout'))
      break
    case 'billpay-done':
      PageComponent = lazy(() => import('./BillPayments/CheckoutDone'))
      break
    case 'parking-register':
      PageComponent = lazy(() => import('./Parking/Register'))
      break
    case 'parking-login':
      PageComponent = lazy(() => import('./Parking/Login'))
      break
    case 'parking-status-2':
      PageComponent = lazy(() => import('./Parking/Status2'))
      break
    case 'parking-enter':
      PageComponent = lazy(() => import('./Parking/Enter'))
      break
    case 'parking-leave':
      PageComponent = lazy(() => import('./Parking/Leave'))
      break
    case 'parking-opening':
      PageComponent = lazy(() => import('./Parking/Opening'))
      break
    case 'parking-info':
      PageComponent = lazy(() => import('./Parking/Info'))
      break
    default:
      PageComponent = lazy(() => import('./NotFound'))
  }

  componentsMap.set(moduleToLoad, PageComponent)
  return PageComponent
}

export default getPageComponent
