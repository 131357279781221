const checkoutStore = (store) => {
  const checkoutInit = {
    checkout: {
      orderCode: '',
      buyerEmail: '',
      buyerName: '',
      nit: '',
      paymentOptionId: 0,
      paymentMethodId: 0,
      backupPaymentMethodId: 0,
      shippingAddressId: 0,
      CVV: '',
      showStatus: false,
      scheduledDate: '',
      scheduledTime: '',
      done: false,
      error: '',
      requiresBillingInfo: false,
      billingInfo: {
        countryCode: '',
        stateCode: '',
        zipCode: '',
      },
      selectedCardInfo: {
        expirationDate: '',
        type: '',
      },
    },
  }
  store.on('@init', () => (checkoutInit))

  store.on('checkout/save', ({ checkout }, data) => ({ checkout: { ...checkout, ...data } }))

  store.on('checkout/updateCVV', ({ checkout }, CVV) => ({ checkout: { ...checkout, CVV } }))

  store.on('checkout/updateShowStatus', ({ checkout }, showStatus) => ({ checkout: { ...checkout, showStatus } }))

  store.on('checkout/done', ({ checkout }) => ({ checkout: { ...checkout, done: true } }))

  store.on('checkout/restetDone', ({ checkout }) => ({ checkout: { ...checkout, done: false } }))

  store.on('checkout/error', ({ checkout }, error) => ({ checkout: { ...checkout, error } }))
}

export default checkoutStore
