import { createStoreon } from 'storeon'
import { routerNavigate } from '@storeon/router'
import { persistState } from '@storeon/localstorage'
import { storeonDevtools } from 'storeon/devtools'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/es-us'

// add other state fragments here
import router from './router'
import user from './user'
import link from './link'
import invoice from './invoice'
import cards from './cards'
import checkout from './checkout'
import customFieldValues from './customFieldValues'
import billPayments from './billpayments'
import track from './track'

dayjs.extend(utc)
dayjs.extend(timezone)

const store = createStoreon([
  router,
  user,
  link,
  invoice,
  checkout,
  cards,
  customFieldValues,
  billPayments,
  track,
  import.meta.env.DEV && storeonDevtools,
  persistState(['user', 'invoice', 'billPayments']),
])
export default store

// Other utility methods:
const formatDate = (date, format = 'DD/MMM/YY HH:mm') => {
  const locale = localStorage.getItem('locale') || navigator.language.split(/[-_]/)[0]
  if (locale === 'es') {
    dayjs.locale('es-us')
  } else {
    dayjs.locale('en')
  }
  const unFormattedDate = dayjs(date)

  if (format) {
    return unFormattedDate.format(format)
  }
  return unFormattedDate
}

const formatDateWithParams = (date, { locale = '', tz = '', format = 'DD/MMM/YY HH:mm' }) => {
  const unFormattedDate = dayjs(date)
  if (locale) unFormattedDate.locale(locale)
  if (tz) unFormattedDate.tz(tz)

  if (format) {
    return unFormattedDate.format(format)
  }
  return unFormattedDate
}

const navigate = (url) => store.dispatch(routerNavigate, url)

const formatCurrency = (num, currencyLocale, currencyCode) => new Intl.NumberFormat(currencyLocale || (store.get().link?.store?.config?.locale ?? 'en-US'), {
  style: 'currency',
  currency: currencyCode || (store.get().link?.store?.config?.currencyCode ?? 'USD'),
}).format(num)

export {
  navigate, formatCurrency, formatDateWithParams, formatDate,
}
