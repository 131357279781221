import React from 'react'
import PropTypes from 'prop-types'
import { useStoreon } from 'storeon/react'
import { ApiContext } from '@hb/web-hooks'

const {
  H4B_API_SECURE: API_SECURE,
  H4B_API_URL: API_URL,
  H4B_API_URL_SANDBOX: API_URL_SANDBOX,
  H4B_API_PORT: API_PORT,
  H4B_API_MOUNT: API_MOUNT,
} = h4benv

const ApiProvider = ({ isSandboxMode, children }) => {
  const { user } = useStoreon('user')

  const config = {
    apiSecure: API_SECURE,
    apiUrl: isSandboxMode ? API_URL_SANDBOX : API_URL,
    apiPort: API_PORT,
    apiMount: API_MOUNT,
    onErrorCallback: (r) => {
      if (r.status === 401) {
        console.error('Token has expired mid session, refreshing')
        // window.location.reload()
        setTimeout(() => {
          const p = new URLSearchParams(window.location.search)
          if (!p.get('reload')) {
            p.set('reload', 1)
            window.location = `${window.location}?${p.toString()}`
          }
        }, 3000)
      }
    },
  }

  return (
    <ApiContext.Provider value={{ token: user.accessToken, config, debug: true }}>
      {children}
    </ApiContext.Provider>
  )
}

ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isSandboxMode: PropTypes.bool.isRequired,
}

export default ApiProvider
