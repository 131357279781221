import React from 'react'
import { createRoot } from 'react-dom/client'

import { StoreContext } from 'storeon/react'
import store from '@store'
import * as Sentry from '@sentry/react'
import { datadogRum } from '@datadog/browser-rum'
import { ApiProvider, AuthProvider } from '@hooks'
import { I18nProvider } from '@hooks/I18nProvider'

import Page from './pages'

// reset css styles
import 'minireset.css'
import '@h4b-dev/components/dist/styles.css'
import './styles/global.css'

const {
  H4B_SANDBOX_URL: SANDBOX_URL,
  H4B_SANDBOX_FORCED: SANDBOX_FORCED,
} = h4benv

const { hostname } = window.location
const isSandboxMode = (SANDBOX_FORCED === 'true' || SANDBOX_URL === hostname)

const App = () => (
  <StoreContext.Provider value={store}>
    <AuthProvider>
      <ApiProvider isSandboxMode={isSandboxMode}>
        <I18nProvider>
          <Page isSandboxMode={isSandboxMode} />
        </I18nProvider>
      </ApiProvider>
    </AuthProvider>
  </StoreContext.Provider>
)

if (import.meta.env.PROD) {
  if (h4benv.H4B_SENTRY_DSN) {
    Sentry.init({
      environment: import.meta.env.DEV ? 'development' : 'production',
      dsn: h4benv.H4B_SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost', '127.0.0.1', /.*h4b\.dev.*/, /.*n1co\.shop.*/],
        }),
        new Sentry.Replay({
          // Additional SDK configuration goes in here, for example:
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: false,
        }),
      ],
      release: '1.0',
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      beforeBreadcrumb(breadcrumb) {
        return (breadcrumb.category === 'xhr'
          && (breadcrumb.data.url.includes('datadog')
          || breadcrumb.data.url.includes('smartlook')))
          ? null
          : breadcrumb
      },
    })
  }

  if (h4benv.H4B_DATADOG_APP_ID
    && h4benv.H4B_DATADOG_CLIENT_TOKEN
    && h4benv.H4B_DATADOG_SITE
    && h4benv.H4B_DATADOG_SERVICE
    && JSON.parse(h4benv.H4B_DATADOG_RECORD_SESSIONS || false)
  ) {
    datadogRum.init({
      applicationId: h4benv.H4B_DATADOG_APP_ID,
      clientToken: h4benv.H4B_DATADOG_CLIENT_TOKEN,
      site: h4benv.H4B_DATADOG_SITE,
      service: h4benv.H4B_DATADOG_SERVICE,
      sampleRate: 100,
      trackInteractions: true,
    })

    if (JSON.parse(h4benv.H4B_DATADOG_RECORD_SESSIONS || false)) {
      datadogRum.startSessionReplayRecording()
    }
  }
}

localStorage.scope = 'same_origin'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)
root.render(<App />)
