const cardsStore = (store) => {
  store.on('@init', () => ({
    cards: {},
  }))

  store.on('cards/saveccv', ({ cards }, { card, ccv }) => ({ cards: { ...cards, [card]: ccv } }))
  store.on('cards/naked', ({ cards }, naked) => ({ cards: { ...cards, naked } }))
}

export default cardsStore
