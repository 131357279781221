// the events list created for tracking users interactions
export default {
  // General events
  NAVIGATE: 'Navigate to:',

  // Checkout
  SET_NOTES_IN_PAYMENT: 'Set if notes are active/inactive in payment ',
  CLICK_IS_NOT_YOU: 'Click on logout',
  OPEN_CHANGE_SHIPMENT_OPTION: 'Open change shipment option modal',
  CLOSE_CHANGE_SHIPMENT_OPTION: 'Close change shipment option modal',
  SET_SHIPMENT_OPTION: 'Set a shipment option',
  OPEN_CARDS_LIST: 'Open cards list sidebar',
  CLOSE_CARDS_LIST: 'Close cards list sidebar',
  SELECT_CARD: 'Select a card',
  REMOVE_CARD: 'Remove card',
  SET_NEEDS_CHANGE: 'Set if payment needs change',
  SELECT_COUNTRY: 'Select country',
  SELECT_STATE: 'Select state',
  SELECT_ADDRESS_IN_MAP: 'Drop pin in map',
  OPEN_ADDRESS_SIDEBAR: 'Open address info sidebar',
  CLOSE_ADDRESS_SIDEBAR: 'Close address info sidebar',
  SELECT_ADDRESS: 'Select an address',
  ADD_ADDRESS: 'Add a new address',
  REMOVE_ADDRESS: 'Remove an address',
  CLOSE_MODAL_MAP: 'Close modal map',
  SAVE_COORDINATES_MAP: 'Save coordinates map info',
  SAVE_ADDRESS_INFO: 'Save address info',
  SET_SCHEDULE_ORDER_DATE: 'Set date to schedule order',
  SET_TIME_ORDER_DATE: 'Set time to schedule order',
  SET_INSTALLMENT: 'Set installment to payment',
  OPEN_COUPONS_MODAL: 'Open coupons modal',
  CLOSE_COUPONS_MODAL: 'Close coupons modal',
  REMOVE_COUPON: 'Remove coupon',
  APPLY_COUPON: 'Apply coupon',
  CLOSE_CVV_MODAL: 'Close cvv modal',
  UPDATE_CVV: 'Update cvv',
  CHECKOUT_DONE: 'Checkout done',
  OPEN_CVV_MODAL: 'Open cvv modal',
  CANCEL_ORDER: 'Cancel order',
  SUBMIT_CUSTOM_DATA: 'Submit custom data',
  SAVE_INVOICE_DATA: 'Save invoice data',
  CLOSE_INVOICE_DATA_MODAL: 'Close invoice data modal',
  OPEN_INVOICE_DATA_MODAL: 'Open invoice data modal',
  DOWNLOAD_VOUCHER: 'Download voucher',
  GO_TO_PROFILE_PAGE: 'Go to Profile page',
  GO_TO_STOREFRONT_PAGE: 'Go to Storefront page',
  LOGIN_ATTEMPT: 'Login attempt',
  SUCCESSFUL_LOGIN: 'Successful Login',
  IM_NOT_INTERESTED: 'User hides banners',
  EXECUTE_BANNER_ACTION: 'Click on Banner in Wuhu page',
}
