import React, {
  useState, useEffect, useRef, lazy,
} from 'react'
import PropTypes from 'prop-types'
import { useStoreon } from 'storeon/react'
import { routerKey } from '@storeon/router'
import { useAuth, useApi } from '@hb/web-hooks'
import * as Sentry from '@sentry/react'
import Smartlook from 'smartlook-client'
import useTrackEvents from '@hooks/useTrackEvents/useTrackEvents'
import decodeToken from '@utils/decodeToken'
import useMessage from '@rottitime/react-hook-message-event'

import E from '@hooks/useTrackEvents/events'
import getPageComponent from './pages'
import { SusEmpty, TwoColumns } from '../components/Layouts'
import { Sidebar } from '../components/Header'
import SandboxHead from '../components/SanboxHead'

const DynamicZendesk = lazy(() => import('react-zendesk'))

const {
  H4B_ZENDESK_KEY, H4B_ZENDESK_COLOR,
  H4B_SMARTLOOK_KEY: smartlookKey,
} = h4benv

const Page = ({ isSandboxMode }) => {
  const { verifyUser } = useAuth()
  const ccApi = useApi({ countryCode: null }, { simpleRequest: true })
  const {
    dispatch, user, [routerKey]: route,
  } = useStoreon('user', routerKey)
  const { trackEvent, initializeTrackingSession } = useTrackEvents()
  const [hasError, setHasError] = useState(false)
  const [parentListener, setParentListener] = useState(false)
  const tokenIsRevalidating = useRef(false)
  const { sendToParent } = useMessage('link-api-connection', (send, payload) => {
    if (payload.connection && payload.origin !== '') {
      setParentListener(true)
    }
  })

  let isLoading = true

  const handleEventDispatch = (payload) => {
    const isIframe = !window.parent.opener

    if (isIframe) {
      window.parent.postMessage({
        type: 'link-api-connection',
        payload,
      }, '*')
    } else {
      sendToParent({ type: 'link-api-connection', payload })
    }
  }

  useEffect(() => {
    const eventProperties = {
      page: route.match.page,
    }
    trackEvent(E.NAVIGATE, eventProperties)
  }, [route.match.page])

  useEffect(() => {
    window.addEventListener('unhandledrejection', (event) => {
      // eslint-disable-next-line no-console
      console.error(
        `UNHANDLED PROMISE REJECTION: ${JSON.stringify(event.reason)}`,
      )
      setHasError(true)
    })

    if (smartlookKey) {
      if (route.match.page && route.match.page.startsWith('parking')) {
        console.log('Smartlook initialized')
        Smartlook.init(smartlookKey)
      }
      if (user.isAuthenticated) {
        dispatch('track/identify', user.userData)
      }
      Smartlook.record({
        emails: true,
        forms: true,
        numbers: true,
      })
    }
  }, [hasError])

  useEffect(() => {
    if (user && user.userData.email && !import.meta.env.DEV) {
      Sentry.configureScope((scope) => {
        scope.setUser({ email: user.userData.email })
      })
    }
    if (user && !user.country) {
      ccApi.handleRequest('GET', 'LinkInfo/GetCountryCode').then((r) => {
        dispatch('user/setCountry', r.countryCode?.toLowerCase() || 'sv')
      })
    }

    if (user) {
      initializeTrackingSession(user.userData.phone)
    }

    // trackEvent('USER', user.userData)
  }, [user.userData])

  useEffect(() => {
    if (parentListener) {
      handleEventDispatch({ connection: true })
    }

    const handleUnload = () => {
      if (route.match.page !== 'wuhu' || route.match.page !== 'link') {
        handleEventDispatch({ cancel: true, path: route.match.page })
      }
    }

    if (parentListener) {
      window.addEventListener('unload', handleUnload)
    }
    return () => {
      if (parentListener) {
        window.removeEventListener('unload', handleUnload)
      }
    }
  }, [parentListener, route.match.page])

  if (route.path === '/authorize') {
    const AuthComponent = getPageComponent('authorization')
    return <AuthComponent />
  }

  if (user.isAuthenticated) {
    // trackEvent('USER Is authenticated')
    if (user.idToken && user.refreshToken && user.accessToken) {
      if (!tokenIsRevalidating.current) { // we only check the token if it is not already updating
        const json = decodeToken(user.accessToken)
        const now = Math.round(Date.now() / 1000)

        if ((now - (user?.tokenOffset ?? 0)) > json.exp) {
          // this token has expired, we will now refresh it
          console.log('Revalidating token', user)
          trackEvent('USER Is revalidating token')
          tokenIsRevalidating.current = true // this serves as a lock of sorts

          verifyUser({
            idtoken: user.idToken,
            refreshtoken: user.refreshToken,
          })
            .then((response) => {
              if (!response?.tokens?.accessToken) {
                console.log('Response was empty', response)
                trackEvent('USER ERROR revalidation response was empty')

                // something has gone terribly wrong
                setHasError(true)
                Sentry.captureException('Failed to obtain a valid user token - Empty backend response', {
                  raw_json: JSON.stringify(response),
                })
                localStorage.clear()
                sessionStorage.clear()
                // this forces the window to procure new tokens, but it might create loops
                window.location.reload()
                return
              }

              if (response.tokens.accessToken === user.accessToken) {
                console.log('Backend returned the same token!')
                console.log('We will try delaying! But not refreshing')
                trackEvent('USER ERROR revalidation response was the same token')

                // we will be using this token for 60 more seconds
                dispatch('user/set', {
                  tokenOffset: user.tokenOffset + 60,
                })

                setTimeout(() => {
                  window.location.reload()
                }, 500)
                // }
                // this error is unrecoverable
              } else {
                console.log('Response was fine', response)
                tokenIsRevalidating.current = false
                trackEvent('USER revalidated')

                // login was successful
                dispatch('user/set', {
                  ...response.tokens,
                  authUser: {
                    ...response.user,
                  },
                  // token offset stores the difference in time between the
                  // user set time in his pc vs the token time
                  tokenOffset: Math.round(Date.now() / 1000) - response.user.iat,
                  // 9 - 10 = -1  // if localtime is behind, it will be negative
                  // 11 - 10 = 1  // if localtime is ahead, it will be positive
                })
                dispatch('track/login', response.user)

                /*
                console.table({
                  tokenOffset: Math.round(Date.now() / 1000) - response.user.iat,
                  now: Math.round(Date.now() / 1000),
                  iat: response.user.iat,
                  nowDate: new Date(),
                  iatDate: new Date(response.user.iat * 1000),
                })
                */

                isLoading = false
              }
            })
            .catch((response) => {
              // eslint-disable-next-line no-console
              console.error('error', response)
              Sentry.captureException('Failed to obtain a valid user token - Catch', {
                raw_json: JSON.stringify(response),
              })
              trackEvent('USER ERROR revalidation failed')

              window.location = '/logout'
            })
        } else {
          // eslint-disable-next-line no-console
          // console.log('Valid user session found', user.accessToken)
          isLoading = false
          // trackEvent('USER Session is reused')
        }
      }
    } else {
      // we dont have a valid user token
      localStorage.clear()
      sessionStorage.clear()

      dispatch('user/set', {
        isAuthenticated: false,
        authUser: {},
      })
      isLoading = false
      trackEvent('USER ERROR user was logged in but no tokens found')
    }
  } else if (route.path !== 'logout') {
    isLoading = false
  }

  // this will be called if the token is refreshing but it is not done yet
  if (isLoading) {
    return <div className="spinner" />
    // return <h1>Loading...</h1>
  }

  const props = {
    props: {
      ...route.match.props,
      eventDispatch: parentListener ? handleEventDispatch : null,
    },
  }

  switch (true) {
    case hasError:
      props.Component = getPageComponent('serverError')
      break
    case !route.match:
      props.Component = getPageComponent('notFound')
      break
    default:
      props.Component = getPageComponent(route.match.page)
      break
  }

  let Layout = SusEmpty

  if (route.match.layout) {
    switch (route.match.layout) {
      default:
        props.Sidebar = Sidebar
        Layout = TwoColumns
        break
    }
  }

  return (
    <>
      {isSandboxMode && (
        <SandboxHead />
      )}
      <Layout {...props} />
      {
        H4B_ZENDESK_KEY && (
          <DynamicZendesk
            zendeskKey={H4B_ZENDESK_KEY}
            color={{
              theme: H4B_ZENDESK_COLOR || '#FFF',
              launcher: H4B_ZENDESK_COLOR || '#FFF',
            }}
            launcher={{
              chatLabel: {
                '*': 'zendesk.chatLabel',
              },
            }}
            chat={{
              title: {
                '*': 'zendesk.chatTitle',
              },
            }}
            onLoaded={() => {
              setTimeout(() => {
                if (window) {
                  const launcher = document.getElementById('launcher')
                  const notification = document.getElementById('schedule-notification')

                  if (launcher && notification) {
                    window.zE('webWidget', 'setLocale', 'es')
                  }
                }
              }, 2000)
            }}
          />
        )
      }
    </>
  )
}

Page.propTypes = {
  isSandboxMode: PropTypes.bool.isRequired,
}

export default Page
