const defaultLinkStructure = {
  code: '',
  store: {},
  order: {},
  buyer: {},
  paymentOptions: {},
  loading: true,
  paymentLink: {},
  effects: {
    customerSessionId: null,
    surcharges: [],
    discounts: [],
    coupons: [],
    couponCodeErrors: [{}],
    deliveryQuoteMessage: {
      message: '',
      success: '',
    },
    deliveryCost: 0,
    tipOptions: [],
    requiresTip: false,
  },
}

const linkStore = (store) => {
  store.on('@init', () => ({ link: defaultLinkStructure }))

  store.on('link/setLinkInfo', ({ link }, linkData) => ({ link: { ...link, ...linkData, loading: false } }))
  store.on('link/setLinkLoading', ({ link }, loading) => ({ link: { ...link, loading } }))
  store.on('link/setCode', ({ link }, code) => ({ link: { ...link, code } }))
  store.on('link/setEffects', ({ link }, effects) => ({ link: { ...link, effects: { ...link.effects, ...effects } } }))
  store.on('link/setCoupons', ({ link }, effects) => ({ link: { ...link, effects: { ...link.effects, ...effects } } }))
  store.on('link/clearLinkInfo', () => ({ link: defaultLinkStructure, loading: false }))
}

export default linkStore
