import React, {
  createContext, useContext, useState, useEffect,
} from 'react'
import { IntlProvider, useFormatMessage } from 'react-intl-hooks'
import { oneOfType, arrayOf, node } from 'prop-types'

const { localStorage } = window
const locale = localStorage.getItem('locale') || navigator.language.split(/[-_]/)[0]

const loadLocaleData = (l) => {
  switch (l) {
    case 'en':
      return import('../translations/en.json')
    default:
      return import('../translations/es.json')
  }
}

const Context = createContext({ locale })

const useTranslation = () => {
  const context = useContext(Context)
  const t = useFormatMessage()

  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider')
  }
  return { ...context, t: ([id]) => t({ id }) }
}

const I18nProvider = ({ children }) => {
  const [lang, setLang] = useState({ locale })

  const switchLang = async (newLocale) => {
    const messages = await loadLocaleData(newLocale)
    localStorage.setItem('locale', newLocale)
    setLang({ locale: newLocale, messages: messages.default })
  }

  useEffect(() => {
    (async () => {
      const messages = await loadLocaleData(locale)
      setLang({ locale, messages: messages.default })
    })()
  }, [])

  const value = {
    lang, switchLang,
  }

  return (
    <Context.Provider value={value}>
      <IntlProvider
        key="i18n"
        locale={lang.locale ?? 'es'}
        messages={lang.messages}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

I18nProvider.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
}

export { I18nProvider, useTranslation }
