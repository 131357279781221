import { createRouter } from '@storeon/router'

export const routeList = [
  [
    '/',
    () => ({
      page: 'profile',
    }),
  ],
  [
    '/404',
    () => ({
      page: 'notFound',
    }),
  ],
  [
    '/authorize',
    () => ({
      page: 'authorize',
    }),
  ],
  [
    '/login',
    () => ({
      page: 'login',
    }),
  ],
  [
    '/logout',
    () => ({
      page: 'logout',
    }),
  ],
  [
    '/pl/*',
    (code) => ({
      page: 'permalink',
      layout: 'twoColumns',
      props: {
        code,
      },
    }),
  ],
  [
    '/billpay',
    () => ({
      page: 'billpay-selection',
    }),
  ],
  [
    '/billpay/checkout',
    () => ({
      page: 'billpay-checkout',
    }),
  ],
  [
    '/billpay/checkout/*/done',
    (serviceBillId) => ({
      page: 'billpay-done',
      props: {
        serviceBillId,
      },
    }),
  ],
  [
    '/billpay/*/details/*',
    (serviceId, billPaymentId) => ({
      page: 'billpay-detail',
      props: {
        serviceId,
        billPaymentId,
      },
    }),
  ],
  [
    '/billpay/login',
    () => ({
      page: 'billpay-login',
    }),
  ],
  [
    '/billpay/*',
    (serviceId) => ({
      page: 'billpay-service',
      props: {
        serviceId,
      },
    }),
  ],
  [
    '/pk/status',
    (visit) => ({
      page: 'parking-status-2',
      props: {
        visit,
      },
    }),
  ],
  [
    '/pk/opening',
    () => ({
      page: 'parking-opening',
    }),
  ],
  [
    '/pk/user',
    () => ({
      page: 'parking-register',
    }),
  ],
  [
    '/pk/register',
    () => ({
      page: 'parking-login',
    }),
  ],
  [
    '/pk/enter/*',
    (gate) => ({
      page: 'parking-enter',
      props: {
        gate,
      },
    }),
  ],
  [
    '/pk/leave/*',
    (gate) => ({
      page: 'parking-leave',
      props: {
        gate,
      },
    }),
  ],
  [
    '/pk/info',
    () => ({
      page: 'parking-info',
    }),
  ],
  [
    '/*/done',
    (code) => ({
      page: 'wuhu',
      layout: 'twoColumns',
      props: {
        code,
      },
    }),
  ],
  [
    '/*/canceled',
    (code) => ({
      page: 'canceled',
      layout: 'twoColumns',
      props: {
        code,
      },
    }),
  ],
  [
    '/*/status',
    (code) => ({
      page: 'status',
      layout: 'twoColumns',
      props: {
        code,
      },
    }),
  ],
  [
    '/*', // /^[^.]+$/,
    (code) => ({
      page: 'link',
      layout: 'twoColumns',
      props: {
        code,
      },
    }),
  ],
]

export default createRouter(routeList)
