import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { css } from 'linaria'
import {
  Price,
  ImagePreview,
  Input,
} from '@hb/web-components'
import { formatCurrency, formatDate } from '@store'
import useTrackEvents from '@hooks/useTrackEvents/useTrackEvents'
import E from '@hooks/useTrackEvents/events'

import { ReactComponent as CheckIcon } from './assets/outline-check.svg'

import { useTranslation } from '@/hooks/I18nProvider'

const styles = css`
  @apply flex flex-col px-5;
  min-width: 300px;
  z-index: 2;
  padding-top: 0 !important;

  .th,
  .tr {
    @apply flex text-xs flex-wrap;
  }

  .th {
    @apply border-b border-mercury-500 font-bold text-gray-500;

    .td:nth-child(1) {
      @apply flex-grow;
      flex-basis: 0;
    }
  }

  .tr {
    @apply border-b border-mercury-500 border-t-0;
  }

  .td {
    @apply py-4;

    img {
      @apply rounded max-w-full;
      max-height: 70px;
    }
  }

  .tr {
    &.empty {
      @apply border-none mt-4;
    }
    &.child {
      @apply w-full border-b-0 mt-0;
      .td {
        @apply pt-0;
          &.name {
          @apply pl-5;
        }
      }
    }

    .td.image {
      @apply w-2/12;
      & > img {
        @apply max-w-full w-16;
        &.empty {
          @apply bg-purple-100;
          background-color: #f8f4fd;
        }
      }
    }

    .td.name {
      @apply flex-grow px-2 flex flex-col justify-center font-bold text-gray-700;
      font-size: 10px;
      flex-basis: 0;

      .info {
        @apply font-normal mt-2 text-gray-500;
        p {
          @apply flex items-center mt-1;
          svg {
            @apply mr-2;
          }
        }
      }
    }

    .td.custom-field {
      @apply flex-grow px-2 flex items-center text-gray-700;
      flex-basis: 0;
    }

    .td.quantity {
      @apply text-right lowercase flex justify-center items-center w-2/12;
    }

    .td.money {
      @apply flex content-end justify-end items-center font-bold text-gray-700 w-2/12 pr-2;
      min-width: 60px;
    }
  }

  .amount {
    @apply flex text-sm shadow-sm mt-4;
    & > div {
      @apply w-full;
    }
  }
    button {
      @apply mt-6;
    }

  .total {
    @apply bg-gray-100 font-bold rounded-md mt-4 mb-4 text-mercury-700 py-4 px-2
      flex text-gray-600 items-center;

    &.savings {
      @apply bg-white;
    }

    .label {
      @apply w-1/2;
    }

    .value {
      @apply flex-grow text-right font-bold;
    }
  }

  .make-savings-green {
    &.tr .td {
      &.name,
      &.money {
        color: #15a292;
      }
    }
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }

  .broken {
    @apply flex font-bold text-gray-700;
    flex-grow: 1;
    justify-content: flex-end;
    padding-top: 0;
    padding-right: 20px;
  }

  div.aditional-data {
    @apply flex justify-between;
    & > div.can-edit {
      @apply text-n1co-carbon-300 text-sm cursor-pointer w-full; 
      margin-right: 10px;

      &:last-of-type {
        @apply text-right;
      }
      & > a {
        @apply text-n1co-carbon-100 inline-flex items-center;
        border-bottom: 1px dotted #aaa;

        & > svg,
        & > img {
          @apply mr-2 h-3 text-n1co-carbon-500 fill-current;
        }
      }
    }
  }

  .input-additional-notes {
    @apply transition-all duration-500 ease-in-out max-h-0 overflow-hidden;
    &.unfolded {
      @apply overflow-y-auto max-h-screen;
    }
    & div.container-label {
      @apply mt-8;
      & > label {
        @apply font-light text-xs text-gray-600 ml-0;
      }
    }

  }

  .effects {
    @apply text-gray-600 text-sm;
    & > div.item {
      @apply flex items-center py-4 px-2 border-b border-gray-200;
      .label {
        @apply w-1/2;
      }

      .value {
        @apply flex-grow text-right font-bold;
      }
    }
  }
`

const Table = ({
  products,
  total,
  totalSubtext,
  currencyLocale,
  currencyCode,
  hasVariableAmount,
  customFields,
  customFieldsDesc,
  canEditCustomData,
  canEditCustomDataFn,
  showNotes,
  orderNotes,
  onChangeOrderNotes,
  discounts = [],
  surcharges = [],
}) => {
  const { t } = useTranslation()
  const { trackEvent } = useTrackEvents()
  const productsWithImages = products
    .filter((p) => !!p.productImageUrl)
    .sort((p) => p.name)
  const productsWithoutImages = products
    .filter((p) => !p.productImageUrl)
    .sort((p) => p.name)
  const orderedProducts = [...productsWithImages, ...productsWithoutImages]

  let totalSavings = 0
  const [unfoldedNotes, setUnfoldedNotes] = useState(false)

  const fields = customFieldsDesc.map((c, i) => ({ ...c, ...customFields[i] }))

  return (
    <section className={`${styles}${hasVariableAmount ? ' variable-amount' : ''}`}>
      <div className="th">
        <div className="td">{t`table.product`}</div>
        <div className="td" />
        <div className="td" />
        {!hasVariableAmount && (
          <div className="td">{t`table.subtotal`}</div>
        )}
      </div>
      {orderedProducts.map((product) => {
        const price = product.promoPrice || product.price
        if (product.promoPrice) {
          totalSavings
            += (product.price - product.promoPrice) * product.quantity
        }
        const tot = (
          <Price
            prevprice={
              product.promoPrice ? formatCurrency(product.price, currencyLocale, currencyCode) : null
            }
          >
            {formatCurrency(price, currencyLocale, currencyCode)}
          </Price>
        )
        const tooLarge = tot.length > 10
        return (
          <div className="tr" key={product.itemId}>
            <div className="td image">
              {product.productImageUrl ? (
                <ImagePreview
                  src={product.productImageUrl}
                  hdSrc={product.productImageUrl}
                />
              ) : (
                <img className="empty" src="/images/product-unnamed.svg" />
              )}
            </div>
            <div className="td name">
              <p>{product.name}</p>
              {
                product?.isSubscription && (
                  <span className="info">
                    {product.trialCyclesNumber && product.trialCyclesNumber > 0 && (
                      <p>
                        <CheckIcon />
                        {`${product.trialCyclesNumber} ${product.trialCycleType} ${t`table.trial`}`}
                      </p>
                    )}
                    {product.cyclesToBillBeforeAllowCancelation && product.cyclesToBillBeforeAllowCancelation > 0 && (
                      <p>
                        <CheckIcon />
                        {`${t`table.minimumCycles`} ${product.cyclesToBillBeforeAllowCancelation} ${product.billingCycleType}`}
                      </p>
                    )}
                    {product.enrollmentEndDate && (
                      <p>
                        <CheckIcon />
                        {`${t`table.enrollmentEnd`} ${formatDate(product.enrollmentEndDate, 'MMMM YYYY')}`}
                      </p>
                    )}
                  </span>
                )
              }
            </div>
            {!hasVariableAmount
              && (product.quantity !== null) && (
              <div className="td quantity">
                {product.quantity}
                ×
              </div>
            )}
            {tooLarge ? (
              <>
                <div className="break" />
                <div className="td broken">{tot}</div>
              </>
            ) : (
              !hasVariableAmount && (
                <div className="td money">{tot}</div>
              )
            )}
            {
                (product.modifiers && product.modifiers.length > 0)
                && product.modifiers.map((modifier) => modifier.selectedOptions.map((option) => (
                  <div className="tr child" key={`${option.selectedOptionId} - ${option.name}`}>
                    <div className="td image" />
                    <div className="td name">{`• ${option.name}`}</div>
                    <div className="td quantity" />
                    <div className="td money">{`+ ${formatCurrency(option.amount)}`}</div>
                  </div>
                )))
              }
          </div>
        )
      })}
      {fields.length > 0 && (
        fields.filter((f) => f.isVisible).map((field) => (
          <div className="tr" key={`custom-field-${field.name}`}>
            <div className="td custom-field">
              {`${field.label}: ${field.value || ''}`}
            </div>
          </div>
        ))
      )}
      {products.length === 0 && (
        <div className="tr empty">{t`table.empty`}</div>
      )}
      {totalSavings > 0 && (
        <div className="tr make-savings-green">
          <div className="td name">{t`table.savedTotal`}</div>
          <div className="td money savings">{formatCurrency(totalSavings, currencyLocale, currencyCode)}</div>
        </div>
      )}

      {(discounts?.length > 0 || surcharges?.length > 0) && (
        <div className="effects">
          {surcharges.map((surcharge) => (
            <div className="item" key={surcharge.id}>
              <div className="label">{surcharge.name}</div>
              <div className="value">{`+ ${formatCurrency(surcharge.amount, currencyLocale, currencyCode)}`}</div>
            </div>
          ))}
          {discounts.map((discount) => (
            <div className="item" key={discount.id}>
              <div className="label">{discount.name}</div>
              <div className="value">{`- ${formatCurrency(discount.amount, currencyLocale, currencyCode)}`}</div>
            </div>
          ))}
        </div>
      )}

      <div className="total">
        <div className="label">{`${t`table.total`} ${totalSubtext && `(${totalSubtext})`}`}</div>
        {!hasVariableAmount ? (
          <div className="value">{formatCurrency(total, currencyLocale, currencyCode)}</div>
        ) : (
          <div className="value">{t`table.pending`}</div>
        )}
      </div>
      <div className="aditional-data">
        {canEditCustomData && (
          <div className="can-edit">
            <a onClick={canEditCustomDataFn}>
              <img src="/images/edit-icon.svg" />
              {t`table.editOrderData`}
            </a>
          </div>
        )}
        {showNotes && (
          <div className="can-edit">
            <a onClick={() => {
              setUnfoldedNotes(!unfoldedNotes)
              trackEvent(E.SET_NOTES_IN_PAYMENT, {
                isActive: !unfoldedNotes,
              })
            }}
            >
              {`+ ${t`total.addOrderNotes`}`}
            </a>
          </div>
        )}
      </div>
      {showNotes && (
        <div className={`input-additional-notes${unfoldedNotes ? ' unfolded' : ''}`}>
          <Input
            name="checkoutNote"
            type="textarea"
            label={t`total.addOrderNotesTitle`}
            placeholder={t`total.addOrderNotesPlaceholder`}
            onChange={(e) => onChangeOrderNotes(e.target.value)}
            value={orderNotes}
            multiple
          />
        </div>
      )}
    </section>
  )
}

Table.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      productImageUrl: PropTypes.string,
      name: PropTypes.string,
      quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      subTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      price: PropTypes.number,
      isSubscription: PropTypes.bool,
      billingCyclesNumber: PropTypes.number,
      trialCyclesNumber: PropTypes.number,
      billingCycleType: PropTypes.string,
      trialCycleType: PropTypes.string,
    }),
  ),
  total: PropTypes.number,
  currencyCode: PropTypes.string,
  currencyLocale: PropTypes.string,
  hasVariableAmount: PropTypes.bool.isRequired,
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ),
  canEditCustomData: PropTypes.bool,
  canEditCustomDataFn: PropTypes.func,
  showNotes: PropTypes.bool,
  orderNotes: PropTypes.string,
  onChangeOrderNotes: PropTypes.func,
  totalSubtext: PropTypes.string,
  discounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      amount: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  surcharges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      amount: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  customFieldsDesc: PropTypes.arrayOf(
    PropTypes.shape({
      defaultValue: PropTypes.string,
      isEditable: PropTypes.bool,
      isRequired: PropTypes.bool,
      isVisible: PropTypes.bool,
      label: PropTypes.string,
      name: PropTypes.string,
      placeholder: PropTypes.string,
    }),
  ),
}

Table.defaultProps = {
  products: [],
  total: 0,
  currencyCode: null,
  currencyLocale: null,
  customFields: [],
  canEditCustomData: false,
  canEditCustomDataFn: () => {},
  showNotes: true,
  orderNotes: '',
  onChangeOrderNotes: () => {},
  totalSubtext: '',
  discounts: [],
  surcharges: [],
  customFieldsDesc: [],
}

export default Table
