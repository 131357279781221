import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { css } from 'linaria'

const dialog = css`
  @apply relative z-40;

  .dialog-bg {
    @apply fixed inset-0 bg-gray-600 bg-opacity-75;
  }

  .sidebar {
    @apply fixed inset-0 z-40 flex;

    @screen lg {
      position: fixed;
      top: 100px;
      left: 50%;
      z-index: 40;
      display: flex;
      width: 500px;
      margin-left: -200px;
      height: 600px;
    }

    .sidebar-dialog {
      @apply relative flex w-full max-w-xs flex-1 flex-col bg-white focus:outline-none;

      .close-button {
        @apply absolute top-0 right-0 -mr-12 pt-2;

        > button {
          @apply ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white cursor-pointer;
        }

        > svg {
          @apply h-6 w-6 text-white;
          fill: #000;
        }
      }

      .sidebar-content {
        overflow-y: auto;
      }
    }
  }

  .bg {
    &-enter {
      @apply transition-opacity ease-linear duration-300;
    }
    &-enterFrom {
      @apply opacity-0;
    }
    &-enterTo {
      @apply opacity-100;
    }
    &-leave {
      @apply transition-opacity ease-linear duration-300;
    }
    &-leaveFrom {
      @apply opacity-100;
    }
    &-leaveTo {
      @apply opacity-0;
    }
  }

  .sb {
    &-enter {
      @apply transition ease-in-out duration-300 transform;
    }
    &-enterFrom {
      @apply -translate-x-full;
    }
    &-enterTo {
      @apply translate-x-0;
    }
    &-leave {
      @apply transition ease-in-out duration-500 transform;
    }
    &-leaveFrom {
      @apply translate-x-0;
    }
    &-leaveTo {
      @apply -translate-x-full;
    }
  }
`
export default ({ children, show, onClose }) => (
  <Transition.Root show={show} as={Fragment}>
    <Dialog as="div" className={dialog} onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="bg-enter"
        enterFrom="bg-enterFrom"
        enterTo="bg-enterTo"
        leave="bg-leave"
        leaveFrom="bg-leaveFrom"
        leaveTo="bg-leaveTo"
      >
        <div className="dialog-bg" />
      </Transition.Child>

      <div className="sidebar">
        <Transition.Child
          as={Fragment}
          enter="sb-enter"
          enterFrom="sb-enterFrom"
          enterTo="sb-enterTo"
          leave="sb-leave"
          leaveFrom="sb-leaveFrom"
          leaveTo="sb-leaveTo"
        >
          <Dialog.Panel className="sidebar-dialog">
            <Transition.Child
              as={Fragment}
              enter="bg-enter"
              enterFrom="bg-enterFrom"
              enterTo="bg-enterTo"
              leave="bg-leave"
              leaveFrom="bg-leaveFrom"
              leaveTo="bg-leaveTo"
            >
              <div className="close-button">
                <button
                  type="button"
                  onClick={onClose}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#000" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000" width="50px" height="50px" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </Transition.Child>
            <div className="sidebar-content">
              {children}
            </div>
          </Dialog.Panel>
        </Transition.Child>
        <div className="w-14 flex-shrink-0" aria-hidden="true">
          {/* Force sidebar to shrink to fit close icon */}
        </div>
      </div>
    </Dialog>
  </Transition.Root>
)
